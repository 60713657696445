<template>
  <div class="page404">
    <div class="main404">
      <img :src="require('@/assets/images/404img.png')" />
      <span>抱歉，您访问的页面不存在,或没有访问权限！</span>
      <a :href="changeProject">回到首页</a>
    </div>
  </div>
</template>

<script>
import BI_config from '@/utils/config'
export default {
  data() {
    return {
      changeProject: this.$commonJs2._isMobile() ? `${BI_config.dashboardProxy}/MobileList` : `${BI_config.dashboardProxy}/list`,
    }
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.page404 {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .main404 {
    text-align: center;
    padding-bottom: 100px;
    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
    span {
      display: block;
      font-size: 16px;
      color: #606266;
    }
    a {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      margin-top: 20px;
      background: #3aa0ff;
      border-radius: 20px;
      border-radius: 20px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
